import { Icon } from "react-icons-kit";
import { ic_open_in_new } from 'react-icons-kit/md/ic_open_in_new';
import Button from "common/src/components/Button";
import OpenInDemo from "../../../../src/docs_shared/button-open-demo.mdx";
import * as React from 'react';
export default {
  Icon,
  ic_open_in_new,
  Button,
  OpenInDemo,
  React
};