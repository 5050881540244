// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js": () => import("./../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/homepage-query.js" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-subscribe-js": () => import("./../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-rocketseat-gatsby-theme-docs-core-templates-docs-query-js": () => import("./../src/@rocketseat/gatsby-theme-docs-core/templates/docs-query.js" /* webpackChunkName: "component---src-rocketseat-gatsby-theme-docs-core-templates-docs-query-js" */)
}

