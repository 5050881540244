import { Icon } from "react-icons-kit";
import { ic_star } from 'react-icons-kit/md/ic_star';
import { ic_phone } from 'react-icons-kit/md/ic_phone';
import { ic_local_hospital } from 'react-icons-kit/md/ic_local_hospital';
import { ic_help } from 'react-icons-kit/md/ic_help';
import { ic_thumb_up } from 'react-icons-kit/md/ic_thumb_up';
import { ic_settings_applications } from 'react-icons-kit/md/ic_settings_applications';
import { ic_flag } from 'react-icons-kit/md/ic_flag';
import { ic_insert_drive_file } from 'react-icons-kit/md/ic_insert_drive_file';
import { ic_done_all } from 'react-icons-kit/md/ic_done_all';
import { ic_cancel } from 'react-icons-kit/md/ic_cancel';
import { ic_pause } from 'react-icons-kit/md/ic_pause';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle';
import { ic_thumb_down } from 'react-icons-kit/md/ic_thumb_down';
import { check } from 'react-icons-kit/oct/check';
import { money } from 'react-icons-kit/fa/money';
import { ic_close } from 'react-icons-kit/md/ic_close';
import { ic_block } from 'react-icons-kit/md/ic_block';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
import { ic_shopping_cart } from 'react-icons-kit/md/ic_shopping_cart';
import * as React from 'react';
export default {
  Icon,
  ic_star,
  ic_phone,
  ic_local_hospital,
  ic_help,
  ic_thumb_up,
  ic_settings_applications,
  ic_flag,
  ic_insert_drive_file,
  ic_done_all,
  ic_cancel,
  ic_pause,
  ic_check_circle,
  ic_thumb_down,
  check,
  money,
  ic_close,
  ic_block,
  ic_warning,
  ic_shopping_cart,
  React
};