import { Icon } from "react-icons-kit";
import { ic_person } from 'react-icons-kit/md/ic_person';
import { ic_info } from 'react-icons-kit/md/ic_info';
import { userTie } from 'react-icons-kit/icomoon/userTie';
import PartsPane from "../../../../src/docs_shared/parts_pane.mdx";
import PartsPaneTech from "../../../../src/docs_shared/parts_pane_tech.mdx";
import OpenInDemo from "../../../../src/docs_shared/button-open-demo.mdx";
import * as React from 'react';
export default {
  Icon,
  ic_person,
  ic_info,
  userTie,
  PartsPane,
  PartsPaneTech,
  OpenInDemo,
  React
};