import { Icon } from "react-icons-kit";
import { ic_open_in_new } from 'react-icons-kit/md/ic_open_in_new';
import { userTie } from 'react-icons-kit/icomoon/userTie';
import { ic_person } from 'react-icons-kit/md/ic_person';
import Button from "common/src/components/Button";
import PartsPane1 from "../../../../src/docs_shared/parts_pane_noAdd.mdx";
import PartsPaneTech1 from "../../../../src/docs_shared/parts_pane_noAdd_tech.mdx";
import OpenInDemo from "../../../../src/docs_shared/button-open-demo.mdx";
import * as React from 'react';
export default {
  Icon,
  ic_open_in_new,
  userTie,
  ic_person,
  Button,
  PartsPane1,
  PartsPaneTech1,
  OpenInDemo,
  React
};