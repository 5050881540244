import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoubleImage = makeShortcode("DoubleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <DoubleImage onClickImg={props.zoomImage} img1={props.imgPane} width1="95%" mdxType="DoubleImage" />
    <ol>
      <li parentName="ol"><strong parentName="li">{`Stock`}</strong>{` shows whether the part is in stock. Used to track which parts are available, and which need to be ordered.`}</li>
      <li parentName="ol"><strong parentName="li">{`Installed`}</strong>{` shows whether part is already installed on the Client.`}</li>
      <li parentName="ol"><strong parentName="li">{`Name`}</strong>{` is the part name.`}</li>
      <li parentName="ol"><strong parentName="li">{`Part #`}</strong>{` is the part model number.`}</li>
      <li parentName="ol"><strong parentName="li">{`Qty`}</strong>{` is quantity for the part.`}</li>
      <li parentName="ol"><strong parentName="li">{`Buy Price`}</strong>{` is buy/supply price. Assigning `}<strong parentName="li">{`Buy Price`}</strong>{` usually means that the part is `}<em parentName="li">{`ordered/in stock`}</em>{`.`}</li>
      <li parentName="ol"><strong parentName="li">{`Markup,%`}</strong>{` is markup added. Default value can be set in `}<a parentName="li" {...{
          "href": "/docs/usage/settings#fees"
        }}><strong parentName="a">{`Settings`}</strong></a>{`. Calculated as: `}<strong parentName="li">{`Markup`}</strong>{` = (`}<strong parentName="li">{`Buy Price`}</strong>{` `}{`*`}{` `}<strong parentName="li">{`Markup,%`}</strong>{`) / 100 * `}<strong parentName="li">{`Qty`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Sell Price`}</strong>{` is part selling price without tax. Calculated as: `}<strong parentName="li">{`Sell Price`}</strong>{` = (`}<strong parentName="li">{`Qty`}</strong>{` * `}<strong parentName="li">{`Buy Price`}</strong>{`) + `}<strong parentName="li">{`Markup`}</strong></li>
      <li parentName="ol"><strong parentName="li">{`Tax`}</strong>{` tax State Tax added. Default value can be set in `}<a parentName="li" {...{
          "href": "/docs/usage/settings#fees"
        }}><strong parentName="a">{`Settings`}</strong></a></li>
      <li parentName="ol"><strong parentName="li">{`Price`}</strong>{` is the final selling price with tax added.`}</li>
      <li parentName="ol"><strong parentName="li">{`Sum of Buy Price`}</strong>{` is all buy prices summed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Sum of Markups`}</strong>{` is all markup values summed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Sum of Sell Price`}</strong>{` is all sell prices without tax summed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Sum of Taxes`}</strong>{` is all tax values summed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Sum of Prices without Excluded`}</strong>{` is all final prices without `}<strong parentName="li">{`Excluded`}</strong>{` ones summed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Sum of Excluded Prices`}</strong>{` is all final `}<strong parentName="li">{`Excluded`}</strong>{` prices summed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Incomplete`}</strong>{` parts are marked with `}<b style={{
          color: "black",
          backgroundColor: "pink",
          padding: 10
        }}>{`RED`}</b>{` background. `}<strong parentName="li">{`Incomplete parts`}</strong>{` don't have `}<strong parentName="li">{`Buy Price`}</strong>{` set.`}</li>
      <li parentName="ol"><strong parentName="li">{`Excluded`}</strong>{` parts are marked with `}<b style={{
          color: "red"
        }}>{`RED`}</b>{` text.`}</li>
      <li parentName="ol">{`Deletes selected part.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      