import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoubleImage = makeShortcode("DoubleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <>{props.quote}</>
    <DoubleImage onClickImg={props.zoomImage} img1={props.imgPane} width1="50%" mdxType="DoubleImage" />
    <ol>
      <li parentName="ol"><strong parentName="li">{`Stock`}</strong>{` shows whether the part is in stock. Used to track which parts are available, and which need to be ordered.`}</li>
      <li parentName="ol"><strong parentName="li">{`Installed`}</strong>{` shows whether part is already installed on the Client.`}</li>
      <li parentName="ol"><strong parentName="li">{`Name`}</strong>{` is the part name.`}</li>
      <li parentName="ol"><strong parentName="li">{`Part #`}</strong>{` is the part model number.`}</li>
      <li parentName="ol"><strong parentName="li">{`Qty`}</strong>{` is quantity for the part.`}</li>
      <li parentName="ol"><strong parentName="li">{`Excluded`}</strong>{` parts are marked with `}<b style={{
          color: "red"
        }}>{`RED`}</b>{` text.`}</li>
      <li parentName="ol">{`Deletes selected part.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      