import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Button from "common/src/components/Button";
import { Icon } from "react-icons-kit";
import { imac } from 'react-icons-kit/ikons/imac';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <a href={props.href} target="_blank">
  <Button style={{
        marginBottom: 10
      }} colors="primaryWithBg" variant="extendedFab" title={props.title || "Open In Demo"} iconPosition="left" icon={<Icon icon={imac} size={24} mdxType="Icon" />} aria-label="open in demo" mdxType="Button" />
    </a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      