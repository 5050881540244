import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DoubleImage = makeShortcode("DoubleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <>{props.quote}</>
    <DoubleImage onClickImg={props.zoomImage} img1={props.imgPane} width1="50%" mdxType="DoubleImage" />
    <ol>
      <li parentName="ol"><strong parentName="li">{`Stock`}</strong>{` shows whether the part is in stock. Used to track which parts are available, and which need to be ordered.`}</li>
      <li parentName="ol"><strong parentName="li">{`Installed`}</strong>{` shows whether part is already installed on the Client.`}</li>
      <li parentName="ol"><strong parentName="li">{`Name`}</strong>{` is the part name.`}</li>
      <li parentName="ol"><strong parentName="li">{`Part #`}</strong>{` is the part model number.`}</li>
      <li parentName="ol"><strong parentName="li">{`Qty`}</strong>{` is quantity for the part.`}</li>
      <li parentName="ol"><strong parentName="li">{`Excluded`}</strong>{` parts are marked with `}<b style={{
          color: "red"
        }}>{`RED`}</b>{` text.`}</li>
      <li parentName="ol">{`Deletes selected part.`}</li>
      <li parentName="ol"><strong parentName="li">{`+1`}</strong>{` adds new part. `}<em parentName="li">{`See below`}</em></li>
    </ol>
    <h3 {...{
      "id": "part-addedit-dialog",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#part-addedit-dialog",
        "aria-label": "part addedit dialog permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Part Add/Edit dialog`}</h3>
    <blockquote>
      <p parentName="blockquote">{`On click of `}<strong parentName="p">{`8.`}</strong>{` above, a dialog appears allowing add/edit of single part.`}</p>
      <DoubleImage onClickImg={props.zoomImage} img1={props.imgDialog} width1="75%" mdxType="DoubleImage" />
    </blockquote>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Stock`}</strong>{` toggles whether the part is in stock.`}</li>
      <li parentName="ol"><strong parentName="li">{`Installed`}</strong>{` toggles whether the part is installed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Excluded`}</strong>{` toggles whether the part is exluded from the insurer covered items, and will be paid by the `}<strong parentName="li">{`Client`}</strong>{`.`}</li>
      <li parentName="ol"><strong parentName="li">{`Name`}</strong>{` is the name of the part.`}</li>
      <li parentName="ol"><strong parentName="li">{`Number`}</strong>{` is the part number. `}<em parentName="li">{`Optional`}</em></li>
      <li parentName="ol"><strong parentName="li">{`Inventory`}</strong>{` shows if part is available in warehouse. `}<em parentName="li">{`See below`}</em></li>
      <li parentName="ol"><strong parentName="li">{`Save`}</strong>{` saves the modifications.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      